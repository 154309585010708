<template>
  <div>
    <supervisor-dashboard />
  </div>
</template>

<script>
import AgentDashboard from "@/views/ce-digital/sub-modules/financial/view/dashboards/AgentDashboard.vue";
import SupervisorDashboard from "@/views/ce-digital/sub-modules/financial/view/dashboards/SupervisorDashboard.vue";
import { mapGetters } from "vuex";
import AssistantDashboard from "@/views/ce-digital/sub-modules/financial/view/dashboards/AssistantDashboard.vue";

export default {
  components: {
    AgentDashboard,
    SupervisorDashboard,
    AssistantDashboard,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style scoped>
</style>
