<template>
  <div>
    <b-row class="w-100 m-0">
      <b-col
        cols="12"
        class="pl-0 pr-0"
      >
        <b-input-group class="mr-1">
          <b-input-group-prepend>
            <b-button
              variant="outline-info"
            >SEARCH CLIENTS</b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchText"
            placeholder="Search by First Name, Last Name or the last 4 digits of the Phone Number"
            @keyup.enter="search"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <modal-global-search
      v-if="modalState"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script>
import ModalGlobalSearch from '@/views/commons/components/clients/modals/ModalGlobalSearch.vue'
import { mapGetters } from "vuex";

export default {
  components: { ModalGlobalSearch },
  data() {
    return {
      modalState: false,
      searchText: '',
      clientsGlobal: [],
      programId: 3,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    search() {
      this.modalState = true;
    },
    closeGlobalSearch() {
      this.modalState = false;
    },
  },
}
</script>

<style>
</style>
