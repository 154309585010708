<template>
  <b-col class="mb-2"
    ><div
      class="p-1 rounded text-center"
      :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
    >
      <h4>Commission</h4>
      <hr />
      <div style="display: flex" class="w-100 justify-content-between">
        <div style="width: 4.375rem" />
        <div style="font-size: 2rem">$ {{ commissionData | currency }}</div>
        <b-button
          size="small"
          variant="primary"
          :to="{ name: 'ced-commissions-financial' }"
        >
          Go
        </b-button>
      </div>
    </div></b-col
  >
</template>

<script>
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import { mapGetters } from "vuex";

export default {
  props: {
    month: {
      type: Number,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      commissionData: 0,
      date: "",
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    async month() {
      await this.getCommissionForDashboardFinancial();
    },
    async year() {
      await this.getCommissionForDashboardFinancial();
    },
  },
  async created() {
    this.date = new Date();
    await this.getCommissionForDashboardFinancial();
  },
  methods: {
    async getCommissionForDashboardFinancial() {
      const { data, status } =
        await FinancialCommissionsService.getCommissionForDashboardFinancial({
          role_id: this.currentUser.role_id,
          user_id: this.currentUser.user_id,
          module_id: this.moduleId,
          month: this.month ? this.month : this.date.getMonth() + 1,
          year: this.year ? this.year : this.date.getFullYear(),
        });
      if (status === 200) {
        this.commissionData = data.amount;
      }
    },
  },
};
</script>

<style scoped>
</style>
