<template>
  <b-modal
    v-model="onControl"
    title="Client's Details"
    size="caro"
    modal-class="modal-primary text-center"
    hide-footer
    title-tag="h3"
    @hidden="closeModal"
  >
    <b-row class="mb-1">
      <b-col md="2" sm="6">
        <span class="text-muted">
          Showing {{ paginate.startPage }} to {{ paginate.toPage }} of
          {{ totalRows }} entries
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6"
        ><b-row>
          <b-col>
            <b-pagination
              v-model="paginate.currentPage"
              :total-rows="totalRows"
              :per-page="paginate.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item" 
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template> </b-pagination
          ></b-col>
          <b-col md="6">
            <div>
              <label>Show</label>
              <v-select
                v-model="paginate.perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[10, 25, 50, 100]"
                :clearable="false" 
                class="per-page-selector d-inline-block mx-50" 
              />
              <label class="mr-2">entries</label>
              <feather-icon
                class="cursor-pointer"
                icon="RefreshCcwIcon"
                size="20"
                @click="refreshTable(3)"
              />
            </div>
          </b-col> </b-row
      ></b-col>
      <b-col md="6">
        <div
          class="d-flex align-items-center justify-content-end align-items-center"
        >
          <b-input-group v-if="!advanceSearch" class="mr-1">
            <b-form-input
              v-model="filters.campo1"
              @keyup.enter="refreshTable(4)"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="refreshTable(5)">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-b-tooltip.bottom="'Advanced search'"
            variant="primary"
            @click="changeAdvanceSearch"
          >
            <div
              class="d-flex justify-content-between"
              style="white-space: nowrap"
            >
              <feather-icon icon="FilterIcon" size="15" class="mr-50" />
              {{ !advanceSearch ? "Advanced Search" : "Basic Search" }}
            </div>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12" class="mt-2">
        <b-row class="justify-content-end">
          <b-col v-if="!advanceSearch" md="7" />
          <b-col v-if="advanceSearch && filters.typePayment != 1" md="2" />
          <template v-if="advanceSearch">
            <b-col md="2">
              <b-form-group>
                <v-select
                  v-model="filters.typePayment"
                  placeholder="Payment Type"
                  :options="[
                    { label: 'All', value: 0 },
                    { label: 'Automatic', value: 1 },
                    { label: 'Manual', value: 2 },
                    { label: 'Others', value: 3 },
                  ]"
                  :reduce="(el) => el.value"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="filters.typePayment == 1" md="2">
              <b-form-group>
                <v-select
                  v-model="filters.day"
                  placeholder="Day Payment"
                  :options="[
                    { label: 'All', value: 0 },
                    { label: '5', value: 5 },
                    { label: '10', value: 10 },
                    { label: '15', value: 15 },
                    { label: '20', value: 20 },
                    { label: '25', value: 25 },
                    { label: '30', value: 30 },
                  ]"
                  :reduce="(el) => el.value"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="receveiableType === 1" md="2">
              <b-form-group>
                <v-select
                  v-model="filters.payment"
                  placeholder="Paid"
                  :options="[
                    { label: 'All', value: 0 },
                    { label: 'YES', value: 1 },
                   // { label: 'PENDING', value: 3 },
                    { label: 'NO', value: 2 },
                  ]"
                  :reduce="(el) => el.value"
                />
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                variant="primary"
                class="btn-icon"
                @click="refreshTable(6)"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <b-table :fields="fields" show-empty sticky-header="50vh" :items="items">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template v-slot:cell(account)="data">
        <span> {{ data.item.account }}</span>
      </template>
      <template v-slot:cell(client_name)="data">
        <span> {{ data.item.client_name }}</span>
      </template>

      <template #cell(payment_type)="data">
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb == 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '0' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'MA'"
          title="Manual"
          :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'OT'"
          title="Others"
          :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
          alt=""
        />
        <span v-if="data.item.day_payment != null">
          ({{ data.item.day_payment }})
        </span>
        <feather-icon
          icon="Edit2Icon"
          class="cursor-pointer ml-1"
          size="15"
          @click="openModalMonthlyPayment(data.item)"
        />
      </template>

      <template #cell(paid)="data">
        <span
          :style="
            data.item.ispayment === 0
              ? 'color:#FF0000'
              : data.item.ispayment === 1
              ? 'color:#00CC00'
              : 'color:orange'
          "
        >
          {{
            data.item.ispayment === 0
              ? "NO"
              : data.item.ispayment === 1
              ? "YES"
              : "PENDING"
          }}
        </span>
      </template>
      <!-- <template #cell(charge)="data">
        <tabler-icon
          v-if="data.item.charges == 0"
          icon="CircleCheckIcon"
          class="cursor-pointer text-success"
          size="22"
          title="Approve Charge"
          @click="openApproveCharge(data.item)"
        />
        <b-img
          v-else
          class="cursor-pointer"
          title="Approve Charge"
          :src="assetsImg + '/images/icons/dcharge.ico'"
          @click="openApproveCharge(data.item)"
        />
      </template> -->

      <template #cell(amount)="data">
        <div v-if="data.item.ispayment == 0 || data.item.ispayment == 2">
          <span class="text-danger">
            {{
              data.item.monthly_amount ? " $ -" + data.item.monthly_amount : ""
            }}</span
          >
        </div>
        <div v-else>
          <ul style="padding-left: 0px; margin-bottom: 0px">
            <li
              v-for="(payment, index) in JSON.parse(data.item.payments)"
              :key="index"
              style="list-style: none"
            >
              <span class="text-success">
                {{ payment.amount ? "$ " + payment.amount : "" }}</span
              > <span v-if="payment.refund" class="text-info" style="font-size:10px">({{payment.refund|currency}})</span>
            </li>
          </ul>
        </div>
      </template>

      <template #cell(date)="data">
        <ul style="padding-left: 0px; margin-bottom: 0px">
          <li
            v-for="(payment, index) in JSON.parse(data.item.payments)"
            :key="index"
            style="list-style: none"
          >
            <span v-if="payment.settlement_date"
              >{{ payment.settlement_date | myGlobal }}
            </span>
          </li>
        </ul>
      </template>
      <template #custom-foot>
        <b-tr class="bg-dark">
          <b-th />
          <b-th />
          <b-th />
          <b-th />
          <b-th />
          <b-th>
            <!-- <div style="margin: 0.5rem" />
            <span class="font-small-3">Paid</span>
            <br />
            <br />
            <div />
            <span v-if="receveiableType === 1" class="font-small-3"
              >Not Paid</span
            >
            <div
          /> -->
          </b-th>
          <b-th class="footer-dark">
            <!-- <div class="footer-span w-100">
              <span class="font-small-3"> $ {{ subTotal | currency }} </span>
            </div>

            <br v-if="receveiableType === 1" />
            <div v-if="receveiableType === 1" class="footer-span w-100">
              <span class="font-small-3"> $ {{ total | currency }} </span>
            </div> -->
          </b-th>
          <!-- <b-th /> -->
        </b-tr>
      </template>
    </b-table>
    <modal-monthly-edit
      v-if="modalMonthlyPayment"
      :client-account="clientAccount"
      :modal-monthly-payment="modalMonthlyPayment"
      @close="closeModalMonthlyPayment"
    />
    <modal-approve-supervisor
      v-if="modalApproveSupervisorOn"
      :modal-approve-supervisor="modalApproveSupervisorOn"
      @closeModalApprove="modalApproveSupervisorOn = false"
    />
    <!-- @approveSup="chargesdisabled({ id: itemId })" -->
  </b-modal>
</template>

<script>
import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";

export default {
  components: {
    vSelect,
    ModalMonthlyEdit,
    ModalApproveSupervisor,
  },
  props: {
    receveiableType: {
      type: Number,
      default: null,
    },
    dateMonth: {
      type: Number,
      default: null,
    },
    dateYear: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      onControl: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
        startPage: 0,
        toPage: 0,
      },
      perPageOptions: [10, 25, 50, 100],
      totalRows: 10,
      advanceSearch: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,

      filters: {
        campo1: "",
        day: "",
        payment: null,
        typePayment: "",
      },
      modalMonthlyPayment: false,

      type: null,

      fields: [
        {
          key: "account",
          label: "Account",
        },
        {
          key: "client_name",
          label: "Client Name",
        },
        {
          key: "mobile",
          label: "Mobile",
        },
        {
          key: "payment_type",
          label: "Payment Type",
        },
        // {
        //   key: "charge",
        //   label: "Charge",
        // },
        {
          key: "paid",
          label: "Paid",
        },

        {
          key: "amount",
          label: "Amount",
        },

        {
          key: "date",
          label: "Date",
        },
      ],

      items: [],
      subTotal: 0,
      total: 0,
      pending: 0,
      clientAccount: "",
      validateChargeOn: false,
      modalApproveSupervisorOn: false,
      itemId: "",
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.onControl = true;
    await this.detailPaymentsProvider();
  },
  methods: {
    changeAdvanceSearch() {
      this.advanceSearch = !this.advanceSearch;

      this.filters.campo1 = "";
      this.filters.payment = null;
      this.filters.day = null;
      this.filters.typePayment = null;
    },
    openModalMonthlyPayment(item) {
      this.clientAccount = item;
      this.modalMonthlyPayment = true;
    },
    closeModalMonthlyPayment() {
      this.modalMonthlyPayment = false;
    },
    closeModal() {
      this.onControl = false;
      this.$emit("close");
    },
    async detailPaymentsProvider() {
      try {
        this.isBusy = true;
        this.addPreloader();

        const params = {
          campo1: this.filters.campo1,
          day: this.filters.day,
          month: this.dateMonth,
          payment: this.receveiableType === 1 ? this.filters.payment : 1,
          type: null,
          typepayment: this.filters.typePayment,
          year: this.dateYear,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
        };

        const data = await DashboardFinancial.getDetailOfClients(params);
        if (data.status === 200) {
          this.items = data.data.data;
          this.subTotal = this.items[0].amount_total;
          this.total = this.items[0].remaining;
          this.pending = this.total - this.subTotal;
          this.totalRows = data.data.total;
          this.paginate.startPage = data.data.from;
          this.paginate.toPage = data.data.to; 
        }
      } catch (error) {
        
        throw error;
      }finally{
        this.removePreloader();
        this.isBusy = false;
      }
    },
    async refreshTable(type){
      console.log('se ejecuto esto :', type);
      await this.detailPaymentsProvider(); 
    },
    // openApproveCharge(item) {
    //   this.itemId = item.id;
    //   if (item.charges === 0) {
    //     if (
    //       this.isCeo ||
    //       this.isSupervisor ||
    //       this.isChief ||
    //       this.isAssistantSupervisor
    //     ) {
    //       this.chargesdisabled(item);
    //     } else {
    //       this.typeApprove = 1;
    //       this.modalApproveSupervisorOn = true;
    //     }
    //   } else {
    //     this.chargesdisabled(item);
    //   }
    // },
    // async chargesdisabled(item) {
    //   const confirm = await this.showConfirmSwal();
    //   if (confirm.isConfirmed) {
    //     this.validateChargeOn = true;
    //     try {
    //       const params = {
    //         id: item.id,
    //         iduser: this.currentUser.user_id,
    //       };
    //       const data = await ClientDashboardService.updateDrb(params);
    //       if (data.status === 200) {
    //         await this.detailPaymentsProvider();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // },
  },
  watch:{
    async "paginate.currentPage" (newVal,oldVal){
      if(newVal != oldVal && !this.isBusy){
        await this.refreshTable(1);
      }
    },
    async "paginate.perPage"(newVal,oldVal){
      if(newVal != oldVal ){ 
        this.paginate.currentPage = 1;
        await this.refreshTable(2);
      }
    }
  }
};
</script>

<style scoped></style>
