<template>
  <div>
    <agent-account-receveiable-tracking-modal
      v-if="agentAccountModal"
      :payment-month="paymentMonth"
      :payment-year="paymentYear"
      :type-transaction="typeTransaction"
      :close-modal="(agentAccountModal = false)"
    />
    <header-slot />

    <b-row>
      <b-col cols="12">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <h2 class="text-center">Scheduled Appointments</h2>
            <hr />
            <b-row>
              <b-col cols="12">
                <schedule-list />
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <modal-detail-payments
      v-if="modalDetailPaymentsOn"
      :payment-type="typePayment"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailPaymentsOn = false"
    />
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :role-id="currentUser.role_id"
      :program-id="programId"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import CommissionDashComponent from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/CommissionDashComponent.vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import AgentAccountReceveiableTrackingModal from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/AgentAccountReceveiableTrackingModal.vue";
import SearchCreditExpertsClients from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/SearchCreditExpertsClients";
import ModalDetailPayments from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/ModalDetailPayments";
import ScheduleList from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/ScheduleList.vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import DebtorReportComponent from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/DebtorReportComponent.vue";

import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ScheduleList,
    VueApexCharts,
    CommissionDashComponent,
    AppEchartDoughnut,
    AgentAccountReceveiableTrackingModal,
    SearchCreditExpertsClients,
    ModalDetailPayments,
    AppEchartBar,
    ModalGlobalSearch,
    DebtorReportComponent,
  },
  data() {
    return {
      weekKeyBar: 0,
      monthKeyBar: 0,
      daylyChange: false,

      monthlyChange: false,
      weeklyBar: {
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },

      monthlyBar: {
        xAxis: [
          {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },
      typePayment: null,
      typeTransaction: 0,
      agentAccountModal: false,
      monthlyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      weeklyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],

      monthKey: 0,
      weekKey: 0,
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#d2b0ff",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series3: "#a4f8cd",
          series2: "#60f2ca",
          series1: "#2bdac7",
        },
      },
      paymentMonth: parseInt(moment().format("MM")),
      paymentYear: parseInt(moment().format("YYYY")),
      paymentsCounter: {},
      optionMonths: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],

      optionYears: [],

      loadingPayments: false,

      loadingCommisions: false,

      loadingMonthly: false,

      weekly: moment().format("YYYY-MM-DD"),
      loadingWeekly: false,

      monthOpts: [],
      // ////////
      commisions: {
        actions: 0,
        charges: 0,
        total: 0,
        sales: 0,
      },
      modalDetailPaymentsOn: false,
      // Global search
      searchText: "",
      modalGlobalSearch: false,
      changeNavAction: true,
      programId: 8,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    optionMonthsTemporal() {
      return this.paymentYear == moment().format("YYYY")
        ? this.optionMonths.slice(0, moment().format("MM"))
        : this.optionMonths;
    },
  },

  watch: {
    skin() {
      this.barOpts.chartOptions.legend.labels.colors = this.isDarkSkin
        ? "rgba(255,255,255,0.6)"
        : "#5e5873";
      this.areaOpts.chartOptions.legend.labels.colors = this.isDarkSkin
        ? "rgba(255,255,255,0.6)"
        : "#5e5873";
      this.weekKey++;
      this.monthKey++;
    },
  },
  mounted() {
    this.getCounterDashboard();
    this.getCounterDashboardCounter();
    this.inicializeProductivity();
  },
  methods: {
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
    changeDaylyProductivity() {
      this.daylyChange = !this.daylyChange;
      this.weekKey++;
      this.weekKeyBar++;
    },
    changeDaylyProductivityMonth() {
      this.monthlyChange = !this.monthlyChange;
      this.monthKey++;
      this.monthKeyBar++;
    },
    openModalDetailPayments(typePayment) {
      this.typePayment = typePayment;
      this.modalDetailPaymentsOn = true;
    },
    async getCounterDashboard() {
      this.loadingPayments = true;
      const params = {
        user_id: this.currentUser.user_id,

        year: this.paymentYear,
        month: this.paymentMonth,
        from_date: moment(moment().startOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
        to_date: moment(moment().endOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
      };
      const data = await DashboardFinancial.getDashboardFinancialInformation(
        params
      );
      if (data.status === 200) {
        this.paymentsCounter = data.data[0];
        const color = [
          "#43A047",
          "#FF8A80",
          "#FF80AB",
          "#EA80FC",
          "#B388FF",
          "#8C9EFF",
          "#82B1FF",
          "#80D8FF",
          "#FF9E80",
          "#00BCD4",
          "#64FFDA",

          "#8D6E63",
        ];
        this.weeklyConfig[0].data = JSON.parse(this.paymentsCounter.week_data);
        this.monthlyConfig[0].data = JSON.parse(
          this.paymentsCounter.month_data
        );
        this.weeklyConfig[0].data.forEach((element, index) => {
          this.weeklyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });
        // recorrer con foreach
        this.monthlyBar.series[0].data = [];
        this.monthlyConfig[0].data.forEach((element, index) => {
          this.monthlyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });
        this.loadingPayments = false;
      }
      this.optionYears = [];
      // years
      for (let x = 2022; x <= moment().format("YYYY"); x++) {
        this.optionYears.push({ text: x, value: x });
      }
    },
  },
};
</script>

<style scoped>
.my-col {
  display: flex;
  flex-direction: column;
}
b-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>