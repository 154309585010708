<template>
  <div>
    <header-slot> </header-slot>
    <b-row class="mb-2">
      <b-col md="6">
        <b-input-group class="mr-1">
          <b-input-group-prepend>
            <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchText"
            placeholder="Search by Name, Account or the last 4 digits of Phone number"
            @keyup.enter="search"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="search">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        ><div class="text-center">
          <b-form-select
            v-model="paymentMonth"
            :options="optionMonthsTemporal"
            @input="refreshDashboard()"
          /></div
      ></b-col>
      <b-col
        ><div class="text-center">
          <b-form-select
            v-model="paymentYear"
            :options="optionYears"
            @input="refreshDashboard()"
          /></div
      ></b-col>
    </b-row>

    <b-row>
      <b-col cols="6" class="mb-1">
        <automatic-client-payments
          :key="keyAutomaticClientPayments"
          :select-year="paymentYear"
          :select-month="paymentMonth"
          :program-id="program"
        />
      </b-col>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <DepartmentExpenses
          :showDate="true"
          :yearDash="paymentYear"
          :monthDash="paymentMonth"
        />
      </b-col>
    </b-row>

    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import SearchDigital from "@/views/commons/components/search-digital/SearchDigital.vue";
import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import CommissionDashComponent from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/CommissionDashComponent.vue";
import SearchCreditExpertsClients from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/SearchCreditExpertsClients";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import ECharts from "vue-echarts";

import theme from "./theme-perso.json";

import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";
import AutomaticClientPayments from "@/views/commons/components/payments/views/components/AutomaticClientPayments.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";


ECharts.registerTheme("theme-color", theme);
export default {
  components: {
    SearchDigital,
    CommissionDashComponent,
    AppEchartDoughnut,
    AppEchartBar,
    SearchCreditExpertsClients,
    ModalGlobalSearch,
    AutomaticClientPayments,
    DepartmentExpenses
  },
  data() {
    return {
      keyAutomaticClientPayments: 0,
      daylyChange: false,
      monthlyChange: false,
      weeklyBar: {
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },

      monthlyBar: {
        xAxis: [
          {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },
      datem: null,
      zeroPaymentOn: false,
      receveiableType: 1,
      modalDetailPaymentsOn: false,
      lengthAgentValidate: 0,
      agentKey: 0,
      option: {
        legend: {
          left: 0,
          selected: {
            January: false,
            February: false,
            March: false,
            April: false,
            May: false,
            June: false,
            July: false,
            August: false,
            September: false,
            October: false,
            November: false,
            December: false,
          },
        },
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: [],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "100px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "January",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "February",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "March",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "April",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "May",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "June",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "July",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "August",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "September",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "October",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "November",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },

          {
            name: "December",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },

      monthlyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 5,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      weeklyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      monthKey: 0,
      weekKey: 0,
      weekKeyBar: 0,
      monthKeyBar: 0,
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#d2b0ff",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series3: "#a4f8cd",
          series2: "#60f2ca",
          series1: "#2bdac7",
        },
      },
      paymentMonth: parseInt(moment().format("MM")),
      paymentYear: parseInt(moment().format("YYYY")),
      paymentsCounter: {},
      paymentsCounterSpecial: {},
      optionMonths: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],

      optionYears: [],

      loadingPayments: false,
      loadingPaymentsCounterSpecial: false,

      loadingCommisions: false,

      loadingMonthly: false,

      weekly: moment().format("YYYY-MM-DD"),
      loadingWeekly: false,

      monthOpts: [],
      // ////////
      commisions: {
        actions: 0,
        charges: 0,
        total: 0,
        sales: 0,
      },
      scheduleListModal: false,
      // Global search
      searchText: "",
      modalGlobalSearch: false,
      changeNavAction: true,
      programId: 8,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    optionMonthsTemporal() {
      return this.paymentYear == moment().format("YYYY")
        ? this.optionMonths.slice(0, moment().format("MM"))
        : this.optionMonths;
    },
    program() {
      return this.$route.matched[0].meta.program;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  mounted() {
    this.getCounterDashboard();
    this.getCounterDashboardCounter();
    this.inicializeProductivity();
  },
  methods: {
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
    inicializeProductivity() {
      const date = moment().format("MM");
      if (date === "1") {
        this.option.legend.selected.January = true;
      } else if (date === "2") {
        this.option.legend.selected.February = true;
      } else if (date === "3") {
        this.option.legend.selected.March = true;
      } else if (date === "4") {
        this.option.legend.selected.April = true;
      } else if (date === "5") {
        this.option.legend.selected.May = true;
      } else if (date === "6") {
        this.option.legend.selected.June = true;
      } else if (date === "7") {
        this.option.legend.selected.July = true;
      } else if (date === "8") {
        this.option.legend.selected.August = true;
      } else if (date === "9") {
        this.option.legend.selected.September = true;
      } else if (date === "10") {
        this.option.legend.selected.October = true;
      } else if (date === "11") {
        this.option.legend.selected.November = true;
      } else if (date === "12") {
        this.option.legend.selected.December = true;
      }
    },
    changeDaylyProductivity() {
      this.daylyChange = !this.daylyChange;
      this.weekKey++;
      this.weekKeyBar++;
    },
    changeDaylyProductivityMonth() {
      this.monthlyChange = !this.monthlyChange;
      this.monthKey++;
      this.monthKeyBar++;
    },
    openModalDetailPayments(receveiableType) {
      this.receveiableType = receveiableType;
      this.modalDetailPaymentsOn = true;
    },
    openZeroPaymentModal(datem) {
      this.datem = datem;
      this.zeroPaymentOn = true;
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async getCounterDashboard() {
      this.loadingPayments = true;
      const params = {
        user_id: this.currentUser.user_id,

        year: this.paymentYear,
        month: this.paymentMonth,
        from_date: moment(moment().startOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
        to_date: moment(moment().endOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
      };
      const data =
        await DashboardFinancial.getDashboardFinancialInformationSupervisor(
          params
        );
      if (data.status === 200) {
        this.option.yAxis[0].data = [];
        this.option.series[0].data = [];
        this.option.series[1].data = [];
        this.option.series[2].data = [];
        this.option.series[3].data = [];
        this.option.series[4].data = [];
        this.option.series[5].data = [];
        this.option.series[6].data = [];
        this.option.series[7].data = [];
        this.option.series[8].data = [];
        this.option.series[9].data = [];
        this.option.series[10].data = [];
        this.option.series[11].data = [];

        this.paymentsCounter = data.data[0];
        this.loadingPayments = false;

        //

        this.weeklyConfig[0].data = JSON.parse(this.paymentsCounter.week_data);
        this.monthlyConfig[0].data = JSON.parse(
          this.paymentsCounter.month_data
        );

        const color = [
          "#43A047",
          "#FF8A80",
          "#FF80AB",
          "#EA80FC",
          "#B388FF",
          "#8C9EFF",
          "#82B1FF",
          "#80D8FF",
          "#FF9E80",
          "#00BCD4",
          "#64FFDA",
          "#8D6E63",
        ];
        let weeklyConfig = this.weeklyConfig[0].data || [];

        weeklyConfig.forEach((element, index) => {
          this.weeklyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });
        // recorrer con foreach
        this.monthlyBar.series[0].data = [];
        this.monthlyConfig[0].data.forEach((element, index) => {
          this.monthlyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });

        this.lengthAgentValidate = this.paymentsCounter.agent_data
          ? JSON.parse(this.paymentsCounter.agent_data).length
          : 0;

        if (this.paymentsCounter.agent_data) {
          // recorrer el array de agentes
          for (
            let i = 0;
            i < JSON.parse(this.paymentsCounter.agent_data).length;
            i++
          ) {
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 1) {
              this.option.series[0].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 2) {
              this.option.series[1].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 3) {
              this.option.series[2].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 4) {
              this.option.series[3].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 5) {
              this.option.series[4].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 6) {
              this.option.series[5].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 7) {
              this.option.series[6].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 8) {
              this.option.series[7].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 9) {
              this.option.series[8].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 10) {
              this.option.series[9].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 11) {
              this.option.series[10].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
            if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 12) {
              this.option.series[11].data.push(
                JSON.parse(this.paymentsCounter.agent_data)[i].count
              );
            }
          }

          // map to push
          const dataga = JSON.parse(this.paymentsCounter.agent_names);

          dataga.forEach((item) => {
            this.option.yAxis[0].data.push(item.agent_name);
          });
          this.agentKey++;
        }
      }
    },

    async getCounterDashboardCounter() {
      this.loadingPaymentsCounterSpecial = true;
      try {
        const params = {
          year: this.paymentYear,
          month: this.paymentMonth,
        };
        const data =
          await DashboardFinancial.getDashboardFinancialInformationSupervisorCounters(
            params
          );
        if (data.status === 200) {
          this.paymentsCounterSpecial = data.data[0];
        }
        this.optionYears = [];
        // years
        for (let x = 2022; x <= moment().format("YYYY"); x++) {
          this.optionYears.push({ text: x, value: x });
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loadingPaymentsCounterSpecial = false;
      }
    },
    openScheduleListModal() {
      this.scheduleListModal = true;
    },
    closeScheduleListModal() {
      this.scheduleListModal = false;
    },
    refreshDashboard() {
      this.getCounterDashboard();
      this.getCounterDashboardCounter();
      this.keyAutomaticClientPayments++;
    },
  },
};
</script>
