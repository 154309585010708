<template>
  <div>
    <header-slot />
    <b-row class="mb-2">
      <b-col md="6">
        <!-- <search-credit-experts-clients class="w-100" /> -->
        <b-input-group class="mr-1">
          <b-input-group-prepend>
            <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchText"
            placeholder="Search by Name, Account or the last 4 digits of Phone number"
            @keyup.enter="search"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="search">
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        ><div class="text-center">
          <b-form-select
            v-model="paymentMonth"
            :options="optionMonthsTemporal"
            @input="getAllInformation"
          /></div
      ></b-col>
      <b-col
        ><div class="text-center">
          <b-form-select
            v-model="paymentYear"
            :options="optionYears"
            @input="getAllInformation"
          /></div
      ></b-col>
    </b-row>

    <b-row>
      <b-col sm="6">
        <b-card style="height: 94% !important" class="p-1" no-body>
          <h2 class="text-center">User</h2>
          <hr />
          <b-row>
            <b-col>
              <b-overlay :show="loadingCommisions" :variant="skin">
                <div class="text-center">
                  <div class="d-flex justify-content-center align-items-center">
                    <div class="mt-1">
                      <feather-icon
                          icon="UserIcon"
                          size="55"
                          class="text-primary mb-2"
                      />
                      <h4 class="mb-1 font-medium-5">
                        {{ currentUser.fullName }}
                      </h4>
                    </div>
                    <div>
                      <feather-icon
                          icon="UserIcon"
                          size="55"
                          class="text-success mb-2"
                      />
                      <span class="font-medium-2 d-inline-block w-100 p-0">{{
                          currentUser.role_name
                        }}</span>
                    </div>
                  </div>
                </div>
              </b-overlay>
            </b-col>
            <commission-dash-component
                :month="paymentMonth"
                :year="paymentYear"
            />
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="6">
        <debtor-report-component :month="paymentMonth" :year="paymentYear"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <h2 class="text-center">Payments</h2>
            <hr />
            <b-row>
              <b-col class="mb-1"
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailPayments(null)"
                >
                  <h4>Monthly</h4>
                  <hr />
                  <span style="font-size: 2rem">{{
                    paymentsCounter.month_counter
                      ? paymentsCounter.month_counter
                      : 0
                  }}</span>
                </div></b-col
              >
              <b-col
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailPayments(null, 1)"
                >
                  <h4>Daily</h4>
                  <hr />
                  <span style="font-size: 2rem">{{
                    paymentsCounter.day_counter
                      ? paymentsCounter.day_counter
                      : 0
                  }}</span>
                </div></b-col
              >
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <b-row>
              <b-col>
                <h2 class="text-center">Daily Productivity</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="daylyChange"
                    name="check-button"
                    switch
                    @click="changeDaylyProductivity()"
                  >
                    Change view
                  </b-form-checkbox>
                </div></b-col
              >
            </b-row>

            <hr />

            <!-- echart -->
            <app-echart-doughnut
              v-if="daylyChange"
              :key="weekKey"
              :series="weeklyConfig"
            />
            <app-echart-bar
              v-else
              :key="weekKeyBar"
              :option-data="weeklyBar"
              theme="theme-colora"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <b-row>
              <b-col>
                <h2 class="text-center">Monthly Productivity</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="monthlyChange"
                    name="check-button"
                    switch
                    @click="changeDaylyProductivityMonth()"
                  >
                    Change view
                  </b-form-checkbox>
                </div></b-col
              >
            </b-row>
            <hr />

            <!-- echart -->
            <app-echart-doughnut
              v-if="monthlyChange"
              :key="monthKey"
              :series="monthlyConfig"
            />

            <app-echart-bar
              v-else
              :option-data="monthlyBar"
              theme="theme-colora"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <h2 class="text-center">Productivity</h2>
            <hr />

            <!-- echart -->
            <app-echart-bar
              :key="agentKey"
              theme="theme-colora"
              :option-data="option"
              :legend="option.legend"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <h2 class="text-center">Receivable Pay In The Month</h2>
            <hr />
            <b-row>
              <b-col class="mb-1"
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Declined Pa</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(1)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.declined_pa
                          ? paymentsCounter.declined_pa
                          : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
              <b-col class="mb-1"
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Manual</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(2)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.manual ? paymentsCounter.manual : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
              <b-col class="mb-1"
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Others</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(3)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.others ? paymentsCounter.others : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
              <b-col
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>1 Month</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(4)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.first_month
                          ? paymentsCounter.first_month
                          : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
              <b-col
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>2 Months</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(5)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.second_month
                          ? paymentsCounter.second_month
                          : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
              <b-col
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Prepaid</h4>
                  <hr />
                  <div
                    class="cursor-pointer"
                    @click="openModalDetailPayments(7)"
                  >
                    <span style="font-size: 2rem">
                      {{
                        paymentsCounter.third_month
                          ? paymentsCounter.third_month
                          : 0
                      }}</span
                    >
                  </div>
                </div></b-col
              >
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingPayments" :variant="skin">
            <h2 class="text-center">Department information</h2>
            <hr />
            <b-row class="cursor-pointer">
              <b-col class="mb-1"
                ><div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Dept Percentage</h4>
                  <hr />
                  <span style="font-size: 2rem">
                    {{
                      paymentsCounterSpecial.per_porcentsage
                        ? paymentsCounterSpecial.per_porcentsage
                        : 0
                    }}</span
                  >
                </div></b-col
              >
              <b-col>
                <div
                  class="p-1 rounded text-center"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDeparmentInfoSc"
                >
                  <h4>Scheduled pay</h4>
                  <hr />
                  <span style="font-size: 2rem">
                    {{
                      paymentsCounterSpecial.scheduled_pay
                        ? paymentsCounterSpecial.scheduled_pay
                        : 0
                    }}</span
                  >
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <modal-detail-payments
      v-if="modalDetailPaymentsOn"
      :payment-type="typePayment"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      :today="today"
      @close="modalDetailPaymentsOn = false"
    />
    <modal-department-info-schedule
      v-if="modalDepartmentInfoScOn"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      :all-list="true"
      @closeModal="closeModalDepartmentInfoSc"
    />
    <modal-global-search
      v-if="modalGlobalSearch"
      :search-text="searchText"
      :program-id="programId"
      :role-id="currentUser.role_id"
      @closeModal="closeGlobalSearch"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import SearchDigital from "@/views/commons/components/search-digital/SearchDigital.vue";
import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import CommissionDashComponent from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/CommissionDashComponent.vue";
import ModalDetailPayments from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/ModalDetailPayments";
import ModalDepartmentInfoSchedule from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/ScheduleListModal.vue";

import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import SearchCreditExpertsClients from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/SearchCreditExpertsClients";
import ECharts from "vue-echarts";
import theme from "./theme-perso.json";

import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";
import DebtorReportComponent
  from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/DebtorReportComponent.vue";

import LabelDebtorReport
  from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/LabelDebtorReport.vue";
import ResultDebtorReport
  from "@/views/ce-digital/sub-modules/financial/view/dashboards/components/ResultDebtorReport.vue";

ECharts.registerTheme("theme-colora", theme);
export default {
  components: {
    VueApexCharts,
    SearchDigital,
    CommissionDashComponent,
    AppEchartDoughnut,
    AppEchartBar,
    SearchCreditExpertsClients,
    ModalDetailPayments,
    ModalDepartmentInfoSchedule,
    ModalGlobalSearch,
    DebtorReportComponent
  },
  data() {
    return {
      today: null,
      weekKeyBar: 0,
      monthKeyBar: 0,
      daylyChange: false,

      monthlyChange: false,
      weeklyBar: {
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },

      monthlyBar: {
        xAxis: [
          {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },
      agentKey: 0,
      modalDetailPaymentsOn: false,
      modalDepartmentInfoScOn: false,
      paymentsCounterSpecial: {},
      option: {
        legend: {
          left: 0,
          selected: {
            January: false,
            February: false,
            March: false,
            April: false,
            May: false,
            June: false,
            July: false,
            August: false,
            September: false,
            October: false,
            November: false,
            December: false,
          },
        },

        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: [],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "100px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "January",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "February",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "March",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "April",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "May",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "June",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "July",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "August",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "September",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "October",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "November",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },

          {
            name: "December",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },

      monthlyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      weeklyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],

      monthKey: 0,
      weekKey: 0,
      chartColors: {
        column: {
          series1: "#826af9",
          series2: "#d2b0ff",
          bg: "#f8d3ff",
        },
        success: {
          shade_100: "#7eefc7",
          shade_200: "#06774f",
        },
        donut: {
          series1: "#ffe700",
          series2: "#00d4bd",
          series3: "#826bf8",
          series4: "#2b9bf4",
          series5: "#FFA1A1",
        },
        area: {
          series3: "#a4f8cd",
          series2: "#60f2ca",
          series1: "#2bdac7",
        },
      },
      paymentMonth: parseInt(moment().format("MM")),
      paymentYear: parseInt(moment().format("YYYY")),
      paymentsCounter: {},
      optionMonths: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],

      optionYears: [],

      loadingPayments: false,

      loadingCommisions: false,

      loadingMonthly: false,

      weekly: moment().format("YYYY-MM-DD"),
      loadingWeekly: false,

      monthOpts: [],
      // ////////
      commisions: {
        actions: 0,
        charges: 0,
        total: 0,
        sales: 0,
      },
      typePayment: null,
      // Global search
      searchText: "",
      modalGlobalSearch: false,
      changeNavAction: true,
      ModalGlobalSearch,
      programId: 8,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    optionMonthsTemporal() {
      return this.paymentYear == moment().format("YYYY")
        ? this.optionMonths.slice(0, moment().format("MM"))
        : this.optionMonths;
    },
  },

  async mounted() {

    await this.getAllInformation();
    this.inicializeProductivity();
  },
  methods: {

    // funcion para correr dos funciones con asinc all

    async getAllInformation() {
      await this.getCounterDashboard();
      await this.getCounterDashboardCounter();
    },
    search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
    inicializeProductivity() {
      const date = moment().format("MM");
      if (date === "1") {
        this.option.legend.selected.January = true;
      } else if (date === "2") {
        this.option.legend.selected.February = true;
      } else if (date === "3") {
        this.option.legend.selected.March = true;
      } else if (date === "4") {
        this.option.legend.selected.April = true;
      } else if (date === "5") {
        this.option.legend.selected.May = true;
      } else if (date === "6") {
        this.option.legend.selected.June = true;
      } else if (date === "7") {
        this.option.legend.selected.July = true;
      } else if (date === "8") {
        this.option.legend.selected.August = true;
      } else if (date === "9") {
        this.option.legend.selected.September = true;
      } else if (date === "10") {
        this.option.legend.selected.October = true;
      } else if (date === "11") {
        this.option.legend.selected.November = true;
      } else if (date === "12") {
        this.option.legend.selected.December = true;
      }
    },

    changeDaylyProductivity() {
      this.daylyChange = !this.daylyChange;
      this.weekKey++;
      this.weekKeyBar++;
    },
    changeDaylyProductivityMonth() {
      this.monthlyChange = !this.monthlyChange;
      this.monthKey++;
      this.monthKeyBar++;
    },
    openModalDetailPayments(typePayment, today) {
      this.typePayment = typePayment;
      this.today = today;
      this.modalDetailPaymentsOn = true;
    },
    openModalDeparmentInfoSc() {
      this.modalDepartmentInfoScOn = true;
    },
    closeModalDepartmentInfoSc() {
      this.modalDepartmentInfoScOn = false;
    },

    async getCounterDashboard() {
      this.loadingPayments = true;
      const params = {
        user_id: this.currentUser.user_id,

        year: this.paymentYear,
        month: this.paymentMonth,
        from_date: moment(moment().startOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
        to_date: moment(moment().endOf("isoWeek").toDate()).format(
          "YYYY-MM-DD"
        ),
      };
      const data =
        await DashboardFinancial.getDashboardFinancialInformationSupervisor(
          params
        );
      if (data.status === 200) {
        this.paymentsCounter = data.data[0];
        this.loadingPayments = false;

        //
        this.option.yAxis[0].data = [];
        this.option.series[0].data = [];
        this.option.series[1].data = [];
        this.option.series[2].data = [];
        this.option.series[3].data = [];
        this.option.series[4].data = [];
        this.option.series[5].data = [];
        this.option.series[6].data = [];
        this.option.series[7].data = [];
        this.option.series[8].data = [];
        this.option.series[9].data = [];
        this.option.series[10].data = [];
        this.option.series[11].data = [];

        this.weeklyConfig[0].data = JSON.parse(this.paymentsCounter.week_data);
        this.monthlyConfig[0].data = JSON.parse(
          this.paymentsCounter.month_data
        );
        const color = [
          "#43A047",
          "#FF8A80",
          "#FF80AB",
          "#EA80FC",
          "#B388FF",
          "#8C9EFF",
          "#82B1FF",
          "#80D8FF",
          "#FF9E80",
          "#00BCD4",
          "#64FFDA",

          "#8D6E63",
        ];

        this.weeklyConfig[0].data.forEach((element, index) => {
          this.weeklyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });
        // recorrer con foreach
        this.monthlyBar.series[0].data = [];
        this.monthlyConfig[0].data.forEach((element, index) => {
          this.monthlyBar.series[0].data.push({
            value: element.value,
            // Specify the style for single bar
            itemStyle: {
              color: color[index],
              shadowColor: "#315190",
              borderType: "dashed",
            },
          });
        });
        // recorrer el array de agentes
        for (
          let i = 0;
          i < JSON.parse(this.paymentsCounter.agent_data).length;
          i++
        ) {
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 1) {
            this.option.series[0].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 2) {
            this.option.series[1].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 3) {
            this.option.series[2].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 4) {
            this.option.series[3].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 5) {
            this.option.series[4].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 6) {
            this.option.series[5].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 7) {
            this.option.series[6].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 8) {
            this.option.series[7].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 9) {
            this.option.series[8].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 10) {
            this.option.series[9].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 11) {
            this.option.series[10].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
          if (JSON.parse(this.paymentsCounter.agent_data)[i].date == 12) {
            this.option.series[11].data.push(
              JSON.parse(this.paymentsCounter.agent_data)[i].count
            );
          }
        }

        // map to push
        const dataga = JSON.parse(this.paymentsCounter.agent_names);

        dataga.forEach((item) => {
          this.option.yAxis[0].data.push(item.agent_name);
        });
        this.optionYears = [];
        // years
        for (let x = 2022; x <= moment().format("YYYY"); x++) {
          this.optionYears.push({ text: x, value: x });
        }
        this.agentKey++;
      }
    },
    async getCounterDashboardCounter() {
      this.loadingPayments = true;
      const params = {
        year: this.paymentYear,
        month: this.paymentMonth,
      };
      const data =
        await DashboardFinancial.getDashboardFinancialInformationSupervisorCounters(
          params
        );
      if (data.status === 200) {
        this.paymentsCounterSpecial = data.data[0];

        this.loadingPayments = false;
      }
      this.optionYears = [];
      // years
      for (let x = 2022; x <= moment().format("YYYY"); x++) {
        this.optionYears.push({ text: x, value: x });
      }
    },
  },
};
</script>
