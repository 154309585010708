<template>
  <b-overlay :show="loadingDebtorReport" :variant="skin">
    <b-card
        style="height: 94% !important"
        no-body
        class="d-flex justify-content-center align-items-center text-center"
    >
      <template #header>
        <h2 class="mb-0 text-uppercase">Debtor Report</h2>
      </template>
      <div class="border-top border-2 pt-1 pb-2">
        <b-button-group size="sm">
          <b-button
              v-for="(btn, idx) in buttons"
              :key="idx"
              @click="clickButton(btn.id)"
              variant="primary"
          >
            {{ btn.caption }}
          </b-button>
        </b-button-group>
        <v-select
          v-if="chosenButton === 3"
          class="mt-1"
          v-model="resultId"
          :options="results"
          :reduce="(option) => option.value"
          label="text"
          @input="clickButton(3)"
        />
        <b-row class="mt-2 mb-2 d-flex justify-content-center" v-if="sumSeries === 0">
          <span class="text-center">No records found</span>
        </b-row>
        <b-row v-else class="mt-2 mb-2">
          <b-col sm="12">
            <VueApexCharts
                height="280"
                width="100%"
                :key="keyChartDebtorClient"
                type="pie"
                :options="chartDebtorClient.chartOptions"
                :series="chartDebtorClient.series"
            />
          </b-col>
        </b-row>
      </div>
      <span class="text-info mb-2">* From {{ '06/'+month+'/'+year }} to {{ '05/'+realMonth+'/'+realYear }}</span>
    </b-card>
  </b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import vSelect from "vue-select";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import {mapGetters} from "vuex";

export default {
  name: "DebtorReportComponent",
  components: {
    VueApexCharts,
    vSelect,
  },
  data() {
    return {
      loadingDebtorReport: false,
      buttons: [
        {
          id: 3,
          caption: "Result - Label",
          state: false,
        },
        {
          id: 2,
          caption: "Result",
          state: false,
        },
        {
          id: 1,
          caption: "Label",
          state: true,
        },


      ],
      chosenButton: 1,
      resultId: 1,
      chartDebtorClient: {
        chartOptions: {
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            labels: { colors: true },
          },
          colors: ["#ff9317", "#1dc45e", "#ff959c", "#4eb3de","#f8e249", "#91d7aa", "#afe7e2", "#bf93a5"],
          labels: ['', ''],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        series: [1,2],
      },
      keyChartDebtorClient: 0,
      recordFounds: true,
      results: [],
    };
  },
  props: {
    month: {
      type: Number,
      default: null,
    },
    year: {
      type: Number,
      default: null,
    },
  },
  async created() {
    this.loadingDebtorReport = true
    await this.getResults()
    await this.getDashboardFinancialLabelDebtorReport();
    this.loadingDebtorReport = false
  },
  computed: {
    sumSeries() {
      let suma = 0
      this.chartDebtorClient.series.forEach (function(numero){
        suma += numero;
      })
      return suma;
    },
    realMonth() {
      if (this.month === 12){
        return '01'
      } else {
        return this.month + 1
      }
    },
    realYear() {
      if (this.month === 12) {
        return this.year + 1
      } else {
        return this.year
      }
    },
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
  },
  methods: {
    async clickButton(id) {
      this.chosenButton = id
      this.buttons.forEach((btn) => {
        btn.state = false;
      });
      this.buttons[id - 1].state = true;
      try {
        this.loadingDebtorReport = true
          if (this.chosenButton === 1) {
          await this.getDashboardFinancialLabelDebtorReport();
        } else if (this.chosenButton === 2) {
          await this.getDashboardFinancialResultDebtorReport();
        } else if (this.chosenButton === 3) {
          await this.getDashboardFinancialResultLabelDebtorReport();
        }
        this.loadingDebtorReport = false
      } catch (error) {
        this.loadingDebtorReport = false
      }
      
    },
    async getDashboardFinancialLabelDebtorReport() {
      const { data, status } = await DashboardFinancial.getDashboardFinancialLabelDebtorReport({
        month: this.month,
        year: this.year
      });
      if (status === 200) {
        this.chartDebtorClient.chartOptions.labels = data.map(
            (item) => item.label
        );
        this.chartDebtorClient.series = data.map((item) => item.clients);
        this.keyChartDebtorClient++;
      }
    },
    async getDashboardFinancialResultDebtorReport() {
      const { data, status } = await DashboardFinancial.getDashboardFinancialResultDebtorReport({
        month: this.month,
        year: this.year
      });
      if (status === 200) {
        this.chartDebtorClient.chartOptions.labels = data.map(
            (item) => item.result
        );
        this.chartDebtorClient.series = data.map((item) => item.clients);
        this.keyChartDebtorClient++;
      }
    },
    async getDashboardFinancialResultLabelDebtorReport() {
      const { data, status } = await DashboardFinancial.getDashboardFinancialResultLabelDebtorReport({
        month: this.month,
        year: this.year,
        result_id: this.resultId,
      });
      if (status === 200) {
        this.chartDebtorClient.chartOptions.labels = data.map(
            (item) => item.label
        );
        this.chartDebtorClient.series = data.map((item) => item.clients);
        this.keyChartDebtorClient++;
      }
    },
    async getResults() {
      const response = await DebtorClientsService.getResultsClient();
      this.results = response;
    },
  },
  watch: {
    async month() {
      this.loadingDebtorReport = true
      await this.clickButton(this.chosenButton);
      this.loadingDebtorReport = false
    },
    async year() {
      this.loadingDebtorReport = true
      await this.clickButton(this.chosenButton);
      this.loadingDebtorReport = false
    },
  },
}
</script>

<style scoped>

</style>