<template>
  <b-modal
    v-model="onControl"
    title="Payment's Details"
    size="lg"
    modal-class="modal-primary text-center"
    hide-footer
    title-tag="h3"
    @hidden="closeModal"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refCategoryClients'].refresh()"
    >
      <template #table>
        <b-table
          ref="refCategoryClients"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="detailPaymentsProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(amount)="data">
            <span>$ {{ data.item.amount | currency }}</span>
          </template>
          <template v-slot:cell(settlement_date)="data">
            <span>{{ data.item.settlement_date | myGlobalDay }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import DashboardFinancial from '@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.'
import { mapGetters } from 'vuex'

export default {
  props: {
    paymentType: {
      type: Number,
      default: null,
    },
    today: {
      type: Number,
      default: null,
    },
    dateMonth:{
      type: Number,
      default: null,
    },
    dateYear:{
      type: Number,
      default: null,
    }

  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'amount',
      sortDesc: true,
      fields: [
        {
          key: 'agent_name',
          label: 'Agent Name',
        },
        {
          key: 'client_name',
          label: 'Client Name',
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'settlement_date',
          label: 'Created At',
          sortable: true,
        },
      ],
      isBusy: false,
      items: [],
      date: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  created() {
    this.onControl = true
  },
  methods: {
    closeModal() {
      this.onControl = false
      this.$emit('close')
    },
    async detailPaymentsProvider(ctx) {

      const params = {
        per_page: ctx.perPage,
        order_by: ctx.sortBy,
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        campo: this.filterPrincipal.model,
        payment_type: this.paymentType,
        month: this.dateMonth,
        year: this.dateYear,
        agent_id: this.isAgent ? this.currentUser.user_id : null,
        today: this.today,
      }
      const data = await DashboardFinancial.getDetailOfPayments(params)
      this.items = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      return this.items || []
    },
  },
}
</script>

<style scoped>

</style>
