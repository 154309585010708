<template>
  <b-card
    no-body
    class="p-2 d-flex justify-content-center align-items-center text-center"
  >
    <template #header>
      <h2 class="mb-0 text-uppercase">Result Debtor Report</h2>
    </template>
    <div class="border-top border-2 pt-1 pb-2">
      <b-row class="mt-2 mb-2">
        <b-col sm="12">
          <b-overlay :show="loadingDebtorReport" :variant="skin">
            <VueApexCharts
              autoresize
              height="340"
              width="100%"
              type="pie"
              :key="keyChartResultDebtorClient"
              :options="chartResultDebtorClient.chartOptions"
              :series="chartResultDebtorClient.series"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
export default {
  props: {
    loadingDebtorReport: {
      type: Boolean,
      required: true,
      default: false,
    },
    keyChartResultDebtorClient: {
      type: Number,
      default: 0,
    },
    chartResultDebtorClient:{
      type: Object,
    }
  },
  components: {
    VueApexCharts,
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
    }),
  },
};
</script>

<style>
</style>